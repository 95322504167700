import React, { useState, useEffect } from 'react';
import api from '../../helper/api';

function AdminSettings() {
  const [newMonthlyLimit, setNewMonthlyLimit] = useState(0);
  const [newExceedFee, setNewExceedFee] = useState(0);
  const [updateMessage, setUpdateMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    // Fetch config data
    const fetchConfigData = async () => {
      try {
        const response = await api.get('/config');
        // Set initial values for editing
        setNewMonthlyLimit(response.data.monthlyLimit);
        setNewExceedFee(response.data.exceedFee);
      } catch (error) {
        console.log(error);
      }
    };

    fetchConfigData();
  }, []);

  const handleUpdateConfig = async () => {
    // Convert to integers
    const monthlyLimit = +newMonthlyLimit;
    const exceedFee = +newExceedFee;

    // Check if values are positive integers
    if (!Number.isInteger(monthlyLimit) || monthlyLimit < 0 || !Number.isInteger(exceedFee) || exceedFee < 0) {
      setErrorMessage('Please enter valid positive integers for Monthly Limit and Exceed Fee.');
      setUpdateMessage('');
      return;
    }

    try {
      // Update config data
      await api.put('/config', {
        monthlyLimit: monthlyLimit,
        exceedFee: exceedFee,
      });
      // Set update message upon successful update
      setUpdateMessage('Settings updated successfully!');
      setErrorMessage('');
    } catch (error) {
      console.log(error);
      // Display error message if the update fails
      setErrorMessage('Error updating settings. Please try again.');
      setUpdateMessage('');
    }
  };

  return (
    <div className="admin-component-tile">
      <h1>App Settings</h1>
      <div className="margin-top">      
        <table className="table">
        <div>    
          <label>Monthly Limit: </label>
          <input
            type="number"
            value={newMonthlyLimit}
            onChange={(e) => setNewMonthlyLimit(e.target.value)}
          />
        </div>
        <div>
          <label>Exceed Fee per delivery($): </label>
          <input
            type="number"
            value={newExceedFee}
            onChange={(e) => setNewExceedFee(e.target.value)}
          />
        </div>
        <div>
          <button onClick={handleUpdateConfig}>Update</button>
        </div>
        <br />
        <div className='success-message'>
          {updateMessage && <p>{updateMessage}</p>}
        </div>
        <div className='error-message' style={{ color: 'red' }}>
          {errorMessage && <p>{errorMessage}</p>}
        </div>

        </table>
      </div>      
    </div>
  );
}

export default AdminSettings;
