// ConfirmationPopup.js
import React from 'react';
import '../styles/modal-popup.css'; // Import the CSS file

const ConfirmationPopup = ({ data, onConfirm, onCancel }) => (
  <div className="modal-popup">
    <div className="modal-popup-content">
        <p>Are you sure you want to save the following information?</p>    
        <div className='margin-top'>
            <strong>Unit:</strong> {data.unit}
        </div>
        <div>
            <strong>Email 1:</strong> {data.email1}
        </div>
        <div>
            <strong>Email 2:</strong> {data.email2 || '<none>'}
        </div>
        <div className='margin-top'>
        If this is incorrect, you will need to contact concierge to change it
        </div>    
        <div className="modal-popup-buttons">   
          <button type="button" onClick={onConfirm}>Confirm</button>
          <button type="button" onClick={onCancel}>Cancel</button>
        </div>
    </div>
  </div>
);

export default ConfirmationPopup;



