// ConfirmationPopup.js
import React from 'react';
import '../styles/modal-popup.css'; // Import the CSS file

const EmailConfirmationPopup = ({ onOk }) => (
  <div className="modal-popup">
    <div className="modal-popup-content">
        <h2>Registration Next Step</h2>
        <div className='margin-top'>
        <p>Please check your email for a verification message. It may take a few minutes to arrive. If you don't see the message in your inbox, please check your <b>spam</b> or <b>junk</b> folder.</p><br />
        <p style={{ color: 'red' }}><b>If you do not complete this step, you will not receive any email alerts.</b></p>
          <button className='modal-popup-buttons' onClick={ onOk }>OK</button>
        </div>
    </div>
  </div>
);

export default EmailConfirmationPopup;



