import axios from 'axios';

const apiURL = process.env.REACT_APP_API_URL

// this is a helper to add the Bearer token to the API request
const api = axios.create({
  baseURL: apiURL,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
  },
  isRefreshingToken: false,
  tokenRefreshQueue: []
});

// Add an interceptor to handle unauthorized errors
api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    // If the request returned a 401 error and it was not a refresh request
    if (error.response.status === 401 && !originalRequest._retry) {

      if (api.isRefreshingToken) {
        // If a refresh is already in progress, wait for it to complete before retrying the request
        await new Promise(resolve => {
          api.tokenRefreshQueue.push(() => {
            resolve();
          });
        });
        return api(originalRequest);
      }

      // Set the flag to indicate that a refresh is in progress
      api.isRefreshingToken = true;

      try {
        // Attempt to refresh the access token
        const response = await axios.post(apiURL + '/auth/refresh', {}, {
          withCredentials: true
        });

        // If the refresh was successful, update the access token and retry the original request
        localStorage.setItem('accessToken', response.data.accessToken);
        api.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;

        // Update the original request's Authorization header with the new access token
        originalRequest.headers['Authorization'] = `Bearer ${response.data.accessToken}`;

        // Retry the original request with the new access token
        const newResponse = await api(originalRequest);

        // Reset the flag to indicate that the refresh is complete
        api.isRefreshingToken = false;
        
        // Process any queued requests that were waiting for the refresh to complete
        if (api.tokenRefreshQueue) {
          api.tokenRefreshQueue.forEach(resolve => resolve());
          api.tokenRefreshQueue = [];
        }

        return newResponse;

      } catch (refreshError) {

        // If the refresh failed, log out the user and redirect to the login page
        localStorage.removeItem('accessToken');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);
export default api;