import { useNavigate } from 'react-router-dom';

function Sidebar({ onResourceSelect }) {

    const navigate = useNavigate();
    
    const handleResourceClick = (resource) => {
      onResourceSelect(resource);
    }

    const handleLogout = () => {
      // Clear access token from local storage
      localStorage.removeItem('accessToken');
  
      // Redirect to login page
      navigate("/login");
    };  
  
    return (
      <div className="sidebar-container">
        <ul>
          <li className="sidebar-entry" onClick={() => handleResourceClick('deliveries')}>
            Delivery Entry
          </li>
          <li className="sidebar-entry" onClick={() => handleResourceClick('pickup')}>
            Delivery Pickup
          </li>
          <li className="sidebar-entry" onClick={() => handleResourceClick('report')}>
            Delivery Report
          </li>
          <li className="sidebar-entry" onClick={() => handleResourceClick('residents')}>
            Residents
          </li>
          {/*
          <li className="sidebar-entry" onClick={() => handleResourceClick('usage')}>
            Usage Reports
          </li>
          <li className="sidebar-entry" onClick={() => handleResourceClick('settings')}>
            App Settings
          </li>
          <li className="sidebar-entry" onClick={() => handleResourceClick('history')}>
            Move History
          </li>
          */}
          <li className="sidebar-entry" onClick={() => handleLogout()} >
            Logout
          </li>
        </ul>
      </div>
    );
  }
  
  export default Sidebar;
  