import React, { useState, useEffect } from 'react';
import api from '../../helper/api';
import '../../styles/table-paginate.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function AdminUsageReport() {
  const [usageData, setUsageData] = useState({});
  const [configData, setConfigData] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [searchText, setSearchText] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState('unit');

  useEffect(() => {
    const fetchUsageData = async () => {
      try {

        // handle timezone so api call is using datestring
        const localDateString = selectedDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });

        // turn it in to ISO time format for moment in the backend
        let currentDate = new Date(localDateString);
        let formattedDate = currentDate.toISOString();
    
        const response = await api.get('/usage', {
          params: {
            // date: localDateString,
            date: formattedDate
          },
        });
    
        setUsageData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchConfigData = async () => {
      try {
        const response = await api.get('/config');
        setConfigData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUsageData();
    fetchConfigData();
  }, [selectedDate]);

  const getFees = (monthlyUsage, monthlyLimit, exceedFee) => {
    if (monthlyUsage > monthlyLimit) {
      const usageBeyondLimit = monthlyUsage - monthlyLimit;
      return usageBeyondLimit * exceedFee;
    }
    return 0;
  };
  
  
  const totalDailyUsage = Object.values(usageData).reduce((sum, { dailyUsage }) => sum + dailyUsage, 0);
  const totalWeeklyUsage = Object.values(usageData).reduce((sum, { weeklyUsage }) => sum + weeklyUsage, 0);
  const totalMonthlyUsage = Object.values(usageData).reduce((sum, { monthlyUsage }) => sum + monthlyUsage, 0);
  const totalFees = Object.values(usageData)
  .filter(({ monthlyUsage }) => monthlyUsage > configData.monthlyLimit)
  .reduce((sum, { monthlyUsage }) => sum + getFees(monthlyUsage, configData.monthlyLimit, configData.exceedFee), 0);


  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortOrder('asc');
      setSortColumn(column);
    }
  };

  const sortedData = Object.keys(usageData)
  .filter((key) =>
    key.toLowerCase().includes(searchText.toLowerCase()) ||
    usageData[key].unit.toLowerCase().includes(searchText.toLowerCase())
  )
  .sort((a, b) => {
    const aValue = sortColumn === 'fees' ? getFees(usageData[a].monthlyUsage) : usageData[a][sortColumn];
    const bValue = sortColumn === 'fees' ? getFees(usageData[b].monthlyUsage) : usageData[b][sortColumn];

    // If sorting by the "Unit" column, convert values to numbers for proper sorting
    if (sortColumn === 'unit') {
      return sortOrder === 'asc' ? parseInt(aValue, 10) - parseInt(bValue, 10) : parseInt(bValue, 10) - parseInt(aValue, 10);
    }

    // For other columns, perform regular sorting
    return sortOrder === 'asc' ? aValue < bValue ? -1 : 1 : aValue > bValue ? -1 : 1;
  });



  return (
    <div className="admin-component-tile">
      <h1>Usage Report</h1>
      <div className="margin-top">
        <div className="date-picker-container">
          <label>
            Select Date:{' '}
            <DatePicker
              className="date-picker"
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="yyyy-MM-dd"
            />
          </label>
          <input
            type="text"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="summary-section">
          <h2>Summary Stats</h2>
          <p>Total Daily Usage: {totalDailyUsage}</p>
          <p>Total Weekly Usage: {totalWeeklyUsage}</p>
          <p>Total Monthly Usage: {totalMonthlyUsage}</p>
          <p>Total Fees: ${totalFees.toFixed(2)}</p>
        </div>
      </div>
      <br />      
      <p>Showing {sortedData.length} records.  All units over monthly limit highlighted in blue.</p>
      <div className="margin-top">
        <table className="table">
          <thead>
            <tr>
              <th onClick={() => handleSort('unit')}>
                Unit {sortColumn === 'unit' && <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>}
              </th>
              <th onClick={() => handleSort('dailyUsage')}>
                Daily Usage {sortColumn === 'dailyUsage' && <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>}
              </th>
              <th onClick={() => handleSort('weeklyUsage')}>
                Weekly Usage {sortColumn === 'weeklyUsage' && <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>}
              </th>
              <th onClick={() => handleSort('monthlyUsage')}>
                Monthly Usage {sortColumn === 'monthlyUsage' && <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>}
              </th>
              <th>
                Fees
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((key) => {
              const { unit, dailyUsage, weeklyUsage, monthlyUsage } = usageData[key];
              const fees = getFees(monthlyUsage, configData.monthlyLimit, configData.exceedFee);

              return (
                <tr key={`${unit}`} className={fees ? 'exceed-limit' : ''} style={{
                  backgroundColor: fees ? 'lightblue' : 'white',
                }}>
                  <td>{unit}</td>
                  <td>{dailyUsage}</td>
                  <td>{weeklyUsage}</td>
                  <td>{monthlyUsage}</td>
                  <td>{fees}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminUsageReport;
