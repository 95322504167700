import DeliveryForm from "./DeliveryForm";
import TodayDeliveries from "./TodayDeliveries";
import { useState } from "react";

function AdminDelivery() {

    // Create a refreshKey state to force TodayDeliveries to refresh
    const [refreshKey, setRefreshKey] = useState({});

    //  Create a highlightedPackageId state to highlight the new packageId
    const [highlightedPackageId, setHighlightedPackageId] = useState(null);

    // Create a function to update the refreshKey and highlightedPackageId states
    const handleDeliveryFormSubmit = (packageId) => {
        setRefreshKey({}); // Update the refreshKey state with a new empty object       
        setHighlightedPackageId(packageId); // Update the highlightedPackageId state with the new packageId
    };

    return (
    <div>
        <DeliveryForm onDeliverySubmit={handleDeliveryFormSubmit} /> 
        <div className="margin-top">
            <TodayDeliveries refreshKey={refreshKey} highlightedPackageId={highlightedPackageId} />
        </div>
    </div>
    );
}

export default AdminDelivery;