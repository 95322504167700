import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./App.css";
import Banner from "./Banner";
import PageHead from "./PageHead"; 
import AdminPage from "./layouts/AdminPage.jsx"; 
import ResidentPage from "./layouts/ResidentPage"; 

function App() {
    
    return (
        <div className="app-container">
            <PageHead />       
            <Banner />
            <Router>
                <Routes>
                    <Route path="/*" element={<ResidentPage />} />
                    <Route path="/admin/*" element={<AdminPage />} />                        
                </Routes>                    
            </Router>
        </div>
    )
}

export default App;