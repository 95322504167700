import React from 'react';
import { Helmet } from "react-helmet";

function PageHead() {

return (
  <Helmet>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins:400,500,700&display=swap" />
    <script src="https://code.jquery.com/jquery-3.6.4.min.js"></script>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>Concierge Delivery Registration</title>
  </Helmet>
);
}

export default PageHead;