import { Route, Routes } from 'react-router-dom';
import UserForm from "../UserForm";
import VerificationStatus from "../VerificationStatus";
import LoginPage from "../LoginPage";
import UnsubscribeStatus from "../UnsubscribeStatus";

const ResidentPage = () => {
    return (
        <Routes>
            <Route path="/" element={<UserForm />} />  
            <Route path="/residents/verify/:hashcode" element={<VerificationStatus />} />  
            <Route path="/residents/unsubscribe/:hashcode" element={<UnsubscribeStatus />} />  
            <Route path="/login" element={<LoginPage />} />          
        </Routes>                    
    )
};

export default ResidentPage;