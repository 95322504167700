import { useState } from 'react';
import RequireAuth from '../RequireAuth';
import AdminResident from '../admin/AdminResident';
import AdminDelivery from '../admin/AdminDelivery';
import AdminPickup from '../admin/AdminPickup';
import AdminDeliveryReport from '../admin/AdminDeliveryReport';
import AdminUsageReport from '../admin/AdminUsageReport';
import AdminSettings from '../admin/AdminSettings';
import AdminHistoricalRecords from '../admin/AdminHistoricalRecords'
import SideBar from '../admin/SideBar';

const AdminPage = () => {

  // This is a state variable that will be used to determine which component is selected
  const [selectedResource, setSelectedResource] = useState('deliveries');

  const handleResourceSelect = (resource) => {
    setSelectedResource(resource);
  }

  const renderSelectedResource = () => {
    switch (selectedResource) {
      case 'deliveries':
        return <AdminDelivery />;
      case 'pickup':
        return <AdminPickup />;
      case 'residents':
        return <AdminResident />;
      case 'report':
        return <AdminDeliveryReport />;
      case 'usage':
        return <AdminUsageReport />;
      case 'settings':
        return <AdminSettings />;
      case 'history':
      return <AdminHistoricalRecords />;
      default:
        return <AdminDelivery />;
    }
  }

  return (
    <div className="admin-page">
      <div className="sidebar">
        <SideBar onResourceSelect={handleResourceSelect} />
      </div>
      <div className="main-container">
        {renderSelectedResource()}
      </div>
    </div>
  );
}

export default RequireAuth(AdminPage);
