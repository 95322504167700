import React, { useState, useEffect } from 'react';
import api from '../../helper/api'
import { formatDate } from "../../helper/date";

const TodayDelivery = ( { refreshKey, highlightedPackageId } ) => {

  // Create a deliveries state to store the deliveries
  const [deliveries, setDeliveries] = useState([]);

  // Create a highlightedRow state to highlight the new packageId
  const [highlightedRow, setHighlightedRow] = useState(null);

  // Fetch today's deliveries
  useEffect(() => {
    const fetchTodayDeliveries = async () => {
        try {
            // make a POST request to the API endpoint
            const response = await api.get('/deliveries/today');
            setDeliveries(response.data);
        }
        catch (error) {
            console.log(error);
        }
    };
    fetchTodayDeliveries();       
  }, [refreshKey]);

  // Highlight the new packageId
  useEffect(() => {
    if (highlightedPackageId) {
      setHighlightedRow(highlightedPackageId);
      const timer = setTimeout(() => {
        setHighlightedRow(null);
      }, 2000); // Highlight for 2 seconds

      return () => clearTimeout(timer);
    }
  }, [highlightedPackageId]);

  // Sort deliveries by packageId in descending order
  const sortedDeliveries = deliveries.sort((a, b) => b.packageId - a.packageId);

  return (
    <div className='admin-component-tile'>
      <h1>Today's Deliveries</h1>
      <p>Showing {sortedDeliveries.length} records</p>
      <table className='table'>
        <thead>
          <tr>
            <th>Package ID</th>
            <th>Unit</th>
            <th>Courier</th>
            <th>Type</th>
            <th>Tracking</th>            
            <th>Location</th>
            <th>Concierge ID</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {sortedDeliveries.map(delivery => (
            <tr
              key={delivery.packageId}
              className={delivery.packageId === highlightedRow  ? 'highlighted' : ''}
            >
              <td>{delivery.packageId}</td>
              <td>{delivery.unit}</td>
              <td>{delivery.courier}</td>
              <td>{delivery.packagetype}</td>
              <td className="cell-tracking" title={delivery.tracking}>{delivery.tracking}</td>
              <td>{delivery.location}</td>
              <td>{delivery.conciergeId}</td>
              <td>{formatDate(delivery.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TodayDelivery;
