import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const VerificationStatus = () => {
    const [status, setStatus] = useState(null);
    const { hashcode } = useParams();

    useEffect(() => {
        const verifyUser = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/residents/verify/${hashcode}`, {
                    method: 'GET',
                });

                if (response.ok) {
                    setStatus('verified');
                } else {
                    setStatus('failed');
                }
            } catch (error) {
                console.error(error);
                setStatus('error');
            }
        };

        if (hashcode) {
            verifyUser();
        }
    }, [hashcode]);

    return (
        <div className='resident-component-tile'>
        <h1>Account Verification</h1>
            {status === 'verified' && <p>Your account has been successfully verified!  You can close this window</p>}
            {status === 'failed' && <p>Verification failed. Please try again or contact support.</p>}
            {status === 'error' && <p>An error occurred during verification. Please try again later.</p>}
        </div>
    );
};

export default VerificationStatus;