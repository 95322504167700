import React from 'react';
import { Navigate } from 'react-router-dom';

const RequireAuth = (Component) => {
  class AuthenticatedComponent extends React.Component {
    render() {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        return <Navigate to="/login" />;
      }
      return <Component {...this.props} />;
    }
  }

  return AuthenticatedComponent;
};

export default RequireAuth;