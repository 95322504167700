import App from "./components/App";
import {
    createRoot
} from 'react-dom/client';

// Bringing in the GoogleOAuthProvider from the package
import { GoogleOAuthProvider } from '@react-oauth/google';

const rootElement = document.getElementById('root');
createRoot(rootElement).render( 
    <GoogleOAuthProvider clientId={ process.env.REACT_APP_GOOGLE_CLIENT_ID } >
        <App />
    </GoogleOAuthProvider>
);
