import React from 'react';

function Banner() {

return (
    <div className='banner-container'>
        <img src="/images/stratalogo.png" alt="strata logo" className="banner-image"></img>
    </div>
);
}
export default Banner;


